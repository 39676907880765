'use client';

import clsx from 'clsx';
import { useContext, useRef } from 'react';
import type { ReactElement, RefObject } from 'react';
import {
  type ComboBoxProps as AriaComboBoxProps,
  ComboBox as ComboBoxPrimitive,
  ComboBoxStateContext,
  Header,
  Input,
  Label,
  ListBox,
  ListBoxItem as ListBoxItemPrimitive,
  type ListBoxItemProps,
  Section,
  type SectionProps,
} from 'react-aria-components';

import { Popover } from '@icelandia/ui/popover';

import { Icon } from '@/components/icon/icon';

export function ListBoxHeader({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <Header className={clsx(className, 'text-cap-base')}>{children}</Header>
  );
}

interface ListBoxSectionProps<T extends object>
  extends Omit<SectionProps<T>, 'children' | 'className'> {
  children: React.ReactNode | ((item: T) => ReactElement);
}

export function ListBoxSection<T extends object>({
  children,
  ...rest
}: ListBoxSectionProps<T>) {
  return (
    <Section className="flex flex-col gap-2" {...rest}>
      {children}
    </Section>
  );
}

export function ListBoxItem(props: ListBoxItemProps) {
  return (
    <ListBoxItemPrimitive
      className="-mx-2 flex items-center gap-2 rounded px-2 py-1 outline-none focus:bg-neutral-30 selected:bg-neutral-30"
      {...props}
    />
  );
}

export function BaseComboBox<T extends object>({
  comboBoxRef,
  isRequired,
  children,
  allowsCustomValue,
  items,
  inputValue,
  onInputChange,
  ...rest
}: {
  comboBoxRef?: RefObject<HTMLDivElement>;
  isRequired?: boolean;
  allowsCustomValue?: boolean;
  children: React.ReactNode;
  items?: ComboBoxProps<T>['items'];
  inputValue?: ComboBoxProps<T>['inputValue'];
  onInputChange?: ComboBoxProps<T>['onInputChange'];
}) {
  return (
    <div ref={comboBoxRef}>
      <ComboBoxPrimitive
        menuTrigger="focus"
        isRequired={isRequired}
        allowsCustomValue={allowsCustomValue}
        items={items}
        inputValue={inputValue}
        onInputChange={onInputChange}
        {...rest}
      >
        {children}
      </ComboBoxPrimitive>
    </div>
  );
}

export function ComboBoxInput({
  placeholder,
  ariaLabel,
  name,
}: {
  placeholder?: string;
  ariaLabel: string;
  name?: string;
}) {
  const state = useContext(ComboBoxStateContext)!;
  return (
    <Input
      name={name}
      aria-label={ariaLabel}
      onClick={() => {
        state.open();
      }}
      className="w-full bg-transparent text-body-base leading-none outline-none"
      placeholder={placeholder}
    />
  );
}

export function FieldContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex w-full flex-row items-center gap-4 rounded-md border border-new-neutral-40 bg-new-neutral-20 px-4 py-3 text-left focus-within:ring">
      <div className="flex-1">{children}</div>
      <div>
        <Icon iconName="caret-down" />
      </div>
    </div>
  );
}

interface ComboBoxProps<T extends object>
  extends Omit<AriaComboBoxProps<T>, 'children'> {
  label: string;
  placeholder?: string;
  isRequired?: boolean;
  name?: string;
  allowsCustomValue?: boolean;
  popoverClassName?: string;
  children: React.ReactNode | ((item: T) => React.ReactNode);
}

export function ComboBoxNew<T extends object>({
  label,
  children,
  placeholder,
  isRequired,
  name,
  allowsCustomValue,
  items,
  inputValue,
  onInputChange,
  ...rest
}: ComboBoxProps<T>) {
  const triggerRef = useRef<HTMLDivElement>(null!);

  return (
    <BaseComboBox
      comboBoxRef={triggerRef}
      isRequired={isRequired}
      allowsCustomValue={allowsCustomValue}
      items={items}
      inputValue={inputValue}
      onInputChange={onInputChange}
      {...rest}
    >
      <FieldContainer>
        <Label className="mb-1 text-cap-xs" elementType="div">
          {label}
        </Label>
        <ComboBoxInput
          ariaLabel={label}
          placeholder={placeholder}
          name={name}
        />
      </FieldContainer>
      <Popover triggerRef={triggerRef}>
        <ListBox className="flex max-h-[300px] flex-col gap-4 overflow-auto">
          {children}
        </ListBox>
      </Popover>
    </BaseComboBox>
  );
}
